import http from '../shared_client_utils/http';

export default {
  fetchServicesWithCategory: async (auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/services/get-with-category`, config);
  },
  fetchServicesForAppointment: async (options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/services/get-for-appointment`, config);
  },
  calculateServicesReport: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/services/calculate-for-analytics`, config);
  },
};
