import http from '../shared_client_utils/http';

export default {
  fetchCustomer: (id, currentClientTime) => {
    return http.get(`${window.baseApiPath}/customers/${id}`, {
      params: { currentClientTime },
    });
  },
  fetchCustomerByAccessToken: (accessToken, currentClientTime) => {
    return http.get(`${window.baseApiPath}/customers/by-access-token/${accessToken}`, {
      params: { currentClientTime },
    });
  },
  sendAccessLink: (data) => {
    return http.put(`${window.baseApiPath}/customers/send-access-link`, { data });
  },
  subscribe: (data) => {
    return http.put(`${window.baseApiPath}/customers/subscribe`, { data });
  },
};
