import http from '../shared_client_utils/http';

export default {
  fetchClient: (id, auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/clients/${id}`, config);
  },
  fetchOutstandingBalance: (id, auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/clients/${id}/get-outstanding-balance`, config);
  },
  mergeClients: (oldClientId, newClientId, auth, options) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.put(`${window.baseApiPath}/clients/${oldClientId}/merge/${newClientId}`, config);
  },
  calculateClientsReport: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/clients/report-for-analytics`, config);
  },
  sendUpdatedIntakeFormEmail: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/clients/send-update-intake-email`, config);
  },
};
